import { Controller, FieldValues, UseControllerProps, UseControllerReturn } from 'react-hook-form';
import React from 'react';
import styled from 'styled-components';
import { Message } from '@naf/input';
import { Checkbox } from '@naf/checkbox';

interface CheckProps {
  label: string;
  disabled?: boolean;
  error?: string;
  checkChildConsents?: () => void;
  triggerSubmit?: (...event: any) => void;
  [rest: string]: any;
}

export const CommunicationCheck = <T extends FieldValues>({
  label,
  disabled = false,
  control,
  defaultValue,
  errorMessage,
  name,
  error,
  checkChildConsents,
  triggerSubmit,
  ...rest
}: UseControllerProps<T> & CheckProps) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <FieldWrapper {...rest}>
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field: { onChange, value } }: UseControllerReturn<T>) => (
        <Checkbox
          onChange={() => {
            onChange({ ...value, consented: !value.consented, undetermined: false });
            if (triggerSubmit) {
              triggerSubmit({ ...value, consented: !value.consented, undetermined: false });
            }
            if (checkChildConsents) {
              checkChildConsents();
            }
          }}
          label={label}
          name={name}
          checked={value.consented === true && !value.undetermined}
          error={typeof errorMessage !== 'undefined' || typeof error !== 'undefined'}
          disabled={disabled}
        />
      )}
    />
    {errorMessage && <Message error>{errorMessage}</Message>}
  </FieldWrapper>
);

const FieldWrapper = styled.div`
  display: inline-flex;
  flex-direction: column;
`;
