import styled, { css } from 'styled-components';
import { Text } from '@naf/text';
import { breakpoints, spacing } from '@naf/theme';
import { Button } from '@naf/button';
import { nafColor } from '@nafcore/theme';
import { Accordion } from '@naf/accordion';
import { Message } from '@naf/input';
import { UserInteraction } from '@naf/user-interaction';

const MyProfileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${spacing.space48};

  h1 {
    margin-bottom: ${spacing.space40};
  }
`;

const BodyText = styled(Text)`
  margin: 0;
`;

const ContentWrapper = styled.div``;

const SubmitButton = styled(Button)`
  margin-left: 24px;
`;

const Form = styled.form``;

const FieldGroupWrapper = styled.div`
  display: flex;
  margin-top: ${spacing.space32};
  flex-direction: column;

  > div:nth-child(2) {
    margin-top: ${spacing.space32};
    margin-left: 0;
  }

  @media (min-width: ${breakpoints.m}) {
    flex-direction: row;
    margin-top: ${spacing.space40};

    > div:nth-child(2) {
      margin-top: 0;
      margin-left: ${spacing.space32};
    }
  }
`;

const FieldWrapper = styled.div`
  margin-top: ${spacing.space32};

  @media (min-width: ${breakpoints.m}) {
    margin-top: ${spacing.space40};
  }
`;

const DateWrapper = styled.div`
  display: flex;
  margin-top: ${spacing.space4};

  > div:not(:last-child) {
    margin-right: ${spacing.space16};
  }

  input {
    text-align: center;
  }
`;

const ConsentWrapper = styled.div<{ hidden?: boolean }>`
  margin-top: ${spacing.space32};
  display: ${(props) => (props.hidden ? 'none' : 'flex')};
  flex-direction: column;
`;

const ConsentText = styled.div`
  display: flex;
  flex-direction: row;

  > span {
    > div {
      display: inline-flex;
      vertical-align: bottom;
      margin-left: ${spacing.space8};
    }
  }
`;

const Metadata = css`
  display: flex;
  flex-direction: row;
  margin-top: ${spacing.space8};
`;

const RadioGroup = styled.div`
  ${Metadata}
`;

const ConsentChild = styled.div<{ $isVisible: boolean }>`
  ${Metadata};
  flex-wrap: wrap;
  display: ${({ $isVisible }) => ($isVisible ? 'flex' : 'none')};

  p {
    flex-basis: 100%;
  }
  > div {
    margin-top: ${spacing.space16};
  }
  > div:not(:last-child) {
    margin-right: ${spacing.space24};
  }
`;

const ErrorMessage = styled(Message)`
  flex-basis: 100%;
`;

const FeedbackWrapper = styled.div`
  margin-top: ${spacing.space80};
`;

const ErrorText = styled(Text)`
  color: ${nafColor.information.errorDark};
`;

const ChipsWrapper = styled.div`
  display: flex;
  @media (max-width: ${breakpoints.m}) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const Caption = styled(Text)`
  margin-top: 0;
`;

const DeleteDataWrapper = styled.div`
  > h3 {
    margin: 0 0 ${spacing.space24};
  }
`;

const PaddedAccordion = styled(Accordion)`
  margin: ${spacing.space32} 0 ${spacing.space48};
`;

const DisplayDataWrapper = styled.div`
  padding-bottom: ${spacing.space64};
  > p {
    margin-bottom: ${spacing.space32};
  }
`;

const ToggleWrapper = styled.div`
  margin-top: ${spacing.space16};
`;

const UserInteractionContainer = styled.div`
  margin-top: ${spacing.space40};
  margin-bottom: ${spacing.space24};
  max-width: 544px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  margin-top: ${spacing.space40};
  flex-direction: column;
  width: max-content;

  > button:nth-child(2) {
    margin-top: ${spacing.space24};
    margin-left: 0;
  }

  @media (min-width: ${breakpoints.m}) {
    flex-direction: row;
    > button:nth-child(2) {
      margin-top: 0;
      margin-left: ${spacing.space24};
    }
  }
`;

const ProfileHeader = styled(Text)`
  margin-top: ${spacing.space40};
  margin-bottom: 0;
  scroll-margin: 100px;
`;

const MyProfileSectionHeader = styled(Text)`
  margin-top: ${spacing.space40};
  margin-bottom: ${spacing.space32};
  scroll-margin: 100px;

  @media (min-width: ${breakpoints.m}) {
    margin-bottom: ${spacing.space40};
  }
`;

const StyledUserInteraction = styled(UserInteraction)`
  max-width: 496px;
  margin-bottom: ${spacing.space24};
  margin-top: ${spacing.space48};

  @media (max-width: ${breakpoints.m}) {
    width: 100%;
    max-width: calc(100% - ${spacing.space48});
    margin-top: ${spacing.space24};
    margin-bottom: 0;
  }

  @media (max-width: ${breakpoints.s}) {
    width: 100%;
    max-width: calc(100% - ${spacing.space32});
  }
`;

const SavedConsentFeedback = styled(UserInteraction)`
  width: fit-content;
  margin-top: ${spacing.space12};
  margin-bottom: ${spacing.space12};
`;

const DeleteMeInfoBox = styled.div`
  display: flex;
  border: 1px solid ${nafColor.information.warning};
  background-color: ${nafColor.information.warningLight};
  padding: ${spacing.space8};
  margin-bottom: ${spacing.space16};
  align-items: center;
  width: fit-content;

  span {
    margin: 0;
    font-size: 1.25rem;
    font-weight: 700;

    @media (max-width: ${breakpoints.m}) {
      font-size: 1rem;
      font-weight: 500;
    }
  }

  svg {
    margin-right: ${spacing.space8};
    align-self: center;
    height: ${spacing.space24};
    width: ${spacing.space24};
    flex-shrink: 0;

    @media (max-width: ${breakpoints.m}) {
      margin-right: ${spacing.space16};
    }
  }

  @media (max-width: ${breakpoints.m}) {
    padding: ${spacing.space8} ${spacing.space12};
  }
`;

const ListTitle = styled(Text)`
  margin-top: ${spacing.space32};
`;

export default {
  ContentWrapper,
  BodyText,
  MyProfileWrapper,
  SubmitButton,
  Form,
  FieldGroupWrapper,
  FieldWrapper,
  DateWrapper,
  ConsentWrapper,
  ConsentText,
  RadioGroup,
  ConsentChild,
  FeedbackWrapper,
  ErrorText,
  ChipsWrapper,
  Caption,
  DeleteDataWrapper,
  PaddedAccordion,
  DisplayDataWrapper,
  ToggleWrapper,
  ErrorMessage,
  UserInteractionContainer,
  ButtonsContainer,
  ProfileHeader,
  MyProfileSectionHeader,
  StyledUserInteraction,
  SavedConsentFeedback,
  DeleteMeInfoBox,
  ListTitle,
};
